import React from "react";
import { useStore } from "@nanostores/react";
import { ActionTypes } from "../constants/actionTypes";
import { actionType, resetActionType } from "../appwrite/atom";

export const useLogoutSideEffect = (setLoading: {
  (value: React.SetStateAction<boolean>): void;
  (arg0: boolean): void;
}) => {
  const actionTypeListener = useStore(actionType);

  React.useEffect(() => {
    if (actionTypeListener === ActionTypes.LOGOUT_SUCCESS) {
      resetActionType();
      setLoading(false);
      window.location.reload();
    } else if (actionTypeListener === ActionTypes.LOGOUT_ERROR) {
      resetActionType();
      setLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionTypeListener]);
};
