import "./styles/theme.css";
import "primereact/resources/primereact.min.css";
import { RouterProvider } from "react-router-dom";
import { AuthRouter } from "./routers/AuthRouter";
import { AppRouter } from "./routers/AppRouter";
import { SESSION } from "./constants/options";
// import { appEntry } from "./appwrite/functions";
import { useAppEntry } from "./hooks/useAppEntry";


function App() {

  const session = useAppEntry();
  

  if (session === SESSION.ACTIVE) {
    return <RouterProvider router={AppRouter} />;
  }

  return <RouterProvider router={AuthRouter} />;
}

export default App;
