/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormikProvider } from "formik";
import { Dialog } from "primereact/dialog";
import { User } from "../../appwrite/types";
import { Button } from "primereact/button";
import Select from "../forms/Select";
import Input from "../forms/Input";

interface Props {
  visible: boolean;
  onHide: () => void;
  title: string;
  formik: any;
  submitLoading?: boolean;
  users: User[];
}

export default function ReportModal({
  visible,
  onHide,
  title,
  formik,
  submitLoading,
  users,
}: Props) {
  return (
    <Dialog
      header={title}
      visible={visible}
      style={{ width: "50vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      onHide={onHide}
    >
      <FormikProvider value={formik}>
        <div className="flex flex-col gap-5 w-full">
          <Select
            id="users"
            label="Select Portfolio"
            options={users?.map((user) => ({
              label: user.name,
              value: user.$id,
            }))}
            optionLabel="label"
            filter
          />

          <Input id="name" label="Name" />

          <Input id="dueDay" label="Due Day" />

          <Button
            loading={submitLoading}
            type="submit"
            label="Submit"
            onClick={(e) => {
              e.preventDefault();
              formik.handleSubmit();
            }}
            className="mt-5 p-button-success"
          />
        </div>
      </FormikProvider>
    </Dialog>
  );
}
