import React from "react";
import { useStore } from "@nanostores/react";
import { ActionTypes } from "../constants/actionTypes";
import { actionType, resetActionType } from "../appwrite/atom";
import { toast } from "react-toastify";

export const useGetReportSideEffect = (
  setLoading: (arg0: boolean) => void
) => {
  const actionTypeListener = useStore(actionType);

  console.log(actionTypeListener);

  React.useEffect(() => {
    if (actionTypeListener === ActionTypes.GET_REPORTS_SUCCESS) {
      setLoading(false);
      resetActionType();
      toast.success("Report fetched successfully");
    } else if (actionTypeListener === ActionTypes.GET_REPORTS_ERROR) {
      setLoading(false);
      resetActionType();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionTypeListener]);
};
