import If from "../shared/If";
import Lottie from "react-lottie";
import * as animationData from "@/assets/lottie/empty.json";
import * as LoadingData from "@/assets/lottie/loading.json";
import file from "../../assets/icons/file.svg";
import { Image } from "primereact/image";
import { Button } from "primereact/button";

interface Props {
  loading: boolean;
  url: string;
  role?: string;
}

export default function ReportViewer({ loading, url }: Props) {
  const empty = JSON.parse(JSON.stringify(animationData));
  const loadingAnimation = JSON.parse(JSON.stringify(LoadingData));


  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loading ? loadingAnimation : empty,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };


  return (
    <div className="flex flex-col items-center justify-center gap-2 bg-white p-5 min-h-[20rem] rounded">
      <If
        condition={loading}
        then={
          <div className="flex flex-col items-center pointer-events-none">
            <Lottie options={defaultOptions} height={250} width={250} />
            <h4 className="text-gray-500">Fetching Report...</h4>
          </div>
        }
        else={
          <If
            condition={!!url}
            then={
              <div className="flex flex-col items-center">
                <Image
                  src={file}
                  alt="file"
                  width={"100"}
                  height={"100"}
                  className="mb-5"
                />

                <Button
                  label="Download Report"
                  className="p-button p-button- p-button-success"
                  iconPos="right"
                  icon="pi pi-download"
                  onClick={() => window.open(url, "_blank")}
                />
              </div>
            }
            else={
              <div className="flex flex-col items-center">
                <div className="pointer-events-none ">
                  <Lottie options={defaultOptions} height={250} width={250} />
                </div>

                <h4 className="-mt-5 text-gray-500">No report to display</h4>
              </div>
            }
          />
        }
      />
    </div>
  );
}
