interface Props {
  submitted: boolean;
  dueDay: number;
}

export default function DueDateWaring({ dueDay, submitted }: Props) {
  const daysMore = dueDay - new Date().getDate();

  const renderColors = () => {
    if (daysMore <= 0 && !submitted) {
      return "text-red-600";
    } else if (submitted) {
      return "text-green-600";
    } else if (daysMore <= 5 && !submitted) {
      return "text-yellow-600";
    } else {
      return "text-gray-600";
    }
  };

  return (
    <div>
      <span className={renderColors()}>
        {submitted
          ? "Submitted"
          : daysMore <= 0
          ? "Overdue"
          : daysMore + " day(s)"}
      </span>
    </div>
  );
}

export const Status = ({
  submitted,
  dueDay,
}: {
  submitted: boolean;
  dueDay: number;
}) => {
  const daysMore = dueDay - new Date().getDate();

  const renderStatus = () => {
    if (daysMore <= 0 && !submitted) {
      return "Overdue";
    } else if (submitted) {
      return "Submitted";
    } else {
      return "Pending";
    }
  };

  return (
    <span
      className={`
        py-1 px-3 rounded
        bg-${
          submitted ? "green" : daysMore <= 0 && !submitted ? "red" : "yellow"
        }-100
        text-${
          submitted ? "green" : daysMore <= 0 && !submitted ? "red" : "yellow"
        }-600
        `}
    >
      {renderStatus()}
    </span>
  );
};
