import {
  LayoutDashboard,
  UsersRound,
  FilePlus2,
  FolderClosed,
  Ratio
} from "lucide-react";
import { ROLES } from "../../constants/options";

const adminRoutes = [
  {
    to: "/dashboard",
    icon: <LayoutDashboard />,
    label: "Dashboard",
    isActive: "dashboard",
  },
  {
    to: "/users",
    icon: <UsersRound />,
    label: "User Management",
    isActive: "users",
  },
  {
    to: "/report-types",
    icon: <Ratio />,
    label: "Report Managemet",
    isActive: "report-types",
  },
  {
    to: "/add-report",
    icon: <FilePlus2 />,
    label: "Add Report",
    isActive: "add-report",
  },
  {
    to: "/reports",
    icon: <FolderClosed />,
    label: "Reports",
    isActive: "reports",
  },
];

const userRoutes = [
  {
    to: "/dashboard",
    icon: <LayoutDashboard />,
    label: "Dashboard",
    isActive: "dashboard",
  },
  {
    to: "/add-report",
    icon: <FilePlus2 />,
    label: "Add Report",
    isActive: "add-report",
  },
  {
    to: "/reports",
    icon: <FolderClosed />,
    label: "Reports",
    isActive: "reports",
  },
];

const mdRoutes = [
  {
    to: "/md-dashboard",
    icon: <LayoutDashboard />,
    label: "Dashboard",
    isActive: "md-dashboard",
  },
  {
    to: "/report-types",
    icon: <Ratio />,
    label: "Report Managemet",
    isActive: "report-types",
  },
  {
    to: "/md-reports",
    icon: <FolderClosed />,
    label: "Reports",
    isActive: "md-reports",
  },
];

export const renderRouteByRole = (role: string) => {
  switch (role) {
    case ROLES.ADMIN:
      return adminRoutes;
    case ROLES.USER:
      return userRoutes;
    case ROLES.MD:
      return mdRoutes;
    default:
      return [];
  }
};
