export const ActionTypes = {
  ADD_REPORT_TYPE_SUCCESS: "reportType/add/success",
  ADD_REPORT_TYPE_ERROR: "reportType/add/error",
  LOGIN_SUCCESS: "login/success",
  LOGIN_ERROR: "login/error",
  LOGOUT_SUCCESS: "logout/success",
  LOGOUT_ERROR: "logout/error",
  SESSION_EXPIRED: "session/expired",
  ADD_REPORT_SUCCESS: "report/add/success",
  ADD_REPORT_ERROR: "report/add/error",
  GET_REPORTS_SUCCESS: "reports/get/success",
  GET_REPORTS_ERROR: "reports/get/error",
};
