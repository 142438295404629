import SideMenu from "./SideMenu";
import { Sidebar } from "primereact/sidebar";

export default function SidebarRenderer({ showMenu, onHide }) {
  return (
    <div className="z-5 fixed h-full">
      {/* large screen */}
      <div className="lg:flex h-full hidden">
        <SideMenu />
      </div>
      {/* small screen */}
      <div className="flex lg:hidden w-[250px]">
        <Sidebar
          visible={showMenu}
          onHide={onHide}
          showCloseIcon={true}
          className="bg-primary-500 bg-primary-500 w-[250px] hidden-lg"
          modal={true}
        >
          <SideMenu onMenuClick={onHide} />
        </Sidebar>
      </div>
    </div>
  );
}
