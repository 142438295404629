/* eslint-disable @typescript-eslint/no-explicit-any */
import DataTable, { createTheme, TableProps } from "react-data-table-component";
import If from "./If";
import { Report } from "../../appwrite/types";
import "../../styles/tableTheme";
import { tableTheme } from "../../styles/tableTheme";

interface Props {
  data: Partial<Report>[];
  columns: any[];
  resetPagination?: boolean;
  pagination?: boolean;
  isRecent?: boolean;
  emptyIcon?: string;
  emptyMessage?: string;
}

type TablePropsType = Props & TableProps<any>;

// optional to override the default dark theme
createTheme(
  "solarized",
  {
    text: {
      primary: "#268bd2",
      secondary: "#2aa198",
    },
    background: {
      default: "#002b36",
    },
    context: {
      background: "#cb4b16",
      text: "#FFFFFF",
    },
    divider: {
      default: "#073642",
    },
    action: {
      button: "rgba(0,0,0,.54)",
      hover: "rgba(0,0,0,.08)",
      disabled: "rgba(0,0,0,.12)",
    },
  },
  "dark"
);

const Table = ({
  data,
  columns,
  resetPagination,
  emptyIcon,
  emptyMessage,
  pagination = true,
  isRecent,
  defaultSortFieldId,
  defaultSortAsc = true,
  ...rest
}: TablePropsType) => {
  return (
    <div className={`overflow-hidden rounded-lg ${isRecent ? " md:p-0" : "md:p-0"}`}>
      <If
        condition={data?.length > 0}
        then={
          <DataTable
            {...rest}
            data={data}
            theme="light"
            columns={columns}
            fixedHeader
            fixedHeaderScrollHeight="72vh"
            sortIcon={<i className="pi pi-sort-alt" />}
            pointerOnHover
            pagination={pagination}
            responsive
            paginationResetDefaultPage={resetPagination}
            paginationPerPage={30}
            defaultSortFieldId={defaultSortFieldId}
            defaultSortAsc={defaultSortAsc}
            // @ts-ignore
            customStyles={tableTheme}
          />
        }
        else={
          <div className="flex flex-col items-center justify-center gap-5 bg-white mt-8 p-10 text-gray-500 rounded">
            <i className={`pi ${emptyIcon} text-5xl text-primary`} />
            <span className="text-lg">{emptyMessage}</span>
          </div>
        }
      />
    </div>
  );
};

export default Table;
