import React from "react";
import {
  Password as PrimeReactPassword,
  PasswordProps,
} from "primereact/password";
import { useField } from "formik";
import { classNames } from "primereact/utils";
import { Divider } from "primereact/divider";

interface Props {
  id: string;
  label?: string;
  isRequired?: boolean;
  lockicon?: boolean;
}

type InputProps = Props & PasswordProps;

const Password = React.forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    const { id, label, isRequired, lockicon } = props;

    const [field, meta] = useField(id);
    const { value, onBlur, onChange } = field;
    const { error, touched } = meta;

    const header = <div className="mb-3 font-bold">Pick a password</div>;
    const footer = (
      <>
        <Divider />
        <p className="mt-2">Suggestions</p>
        <ul className="mt-0 ml-2 pl-2 line-height-3">
          <li>At least one lowercase</li>
          <li>At least one uppercase</li>
          <li>At least one numeric</li>
          <li>Minimum 8 characters</li>
        </ul>
      </>
    );

    return (
      <div className="flex flex-col gap-2 w-full">
        <label
          htmlFor={id}
          className={classNames({
            "p-error": touched && error,
          })}
        >
          {label} {isRequired && <span className="p-error">*</span>}
        </label>

        <span className="p-input-icon-left">
          {!!lockicon && <i className="z-10 pi pi-lock" />}
          <PrimeReactPassword
            // @ts-ignore
            ref={ref}
            {...props}
            value={value}
            onChange={(e) => {
              onChange(id)(e.target.value);
            }}
            onBlur={onBlur(id)}
            inputStyle={{
              paddingLeft: lockicon ? 40 : undefined,
              width: "100%",
              borderRadius: 3.4
            }}
            inputClassName="border-gray-300"
            className={classNames({
              "p-invalid": touched && error,
              "w-full": true,
            })}
            toggleMask
            header={header}
            footer={footer}
          />
        </span>

        {touched && error && <small className="p-error">{error}</small>}
      </div>
    );
  }
);

Password.displayName = "Password";
export default Password;
