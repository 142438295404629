interface TitleProps {
  title: string;
}

export default function Title({ title }: TitleProps) {
  return (
    <div className="flex items-center justify-between w-full lg:w-7/12">
      <h1 className="font-medium text-3xl text-gray-800 md:text-4xl">
        {title}
      </h1>
    </div>
  );
}
