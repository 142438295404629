import React from "react";
import { FormikProvider, useFormik } from "formik";
import Title from "../../components/shared/Title";
import type { Report } from "../../types";
import * as Yup from "yup";
import { Toast } from "primereact/toast";
import { FileUpload, FileUploadSelectEvent } from "primereact/fileupload";

import Select from "../../components/forms/Select";
import Input from "../../components/forms/Input";
import { Button } from "primereact/button";
import { addNewReport, getReportTypes } from "../../appwrite/functions";
import { useQuery } from "react-query";
import { ReportType } from "../../appwrite/types";
import { getUser } from "../../appwrite/atom";
import { useAddReportSideEffect } from "../../hooks/useAddReportSideEffect";

export default function AddReport() {
  const toast = React.useRef<Toast>(null);

  const { data } = useQuery("getReportTypes", getReportTypes);
  const [loading, setLoading] = React.useState(false);
  // filter report type for current user
  const { uid } = getUser();

  const userReportTypes = data?.filter(
    // @ts-ignore
    (report: ReportType) => report?.users?.uid === uid
  );

  const formik = useFormik({
    initialValues: {
      uid: uid ?? "",
      reportType: "",
      dueDate: "",
      file: null,
      name: "",
    },
    validationSchema: Yup.object({
      reportType: Yup.string().required("Report type is required"),
      file: Yup.mixed()
        .required("File is required")
        .test("fileSize", "File size is too large", (value) => {
          // @ts-ignore
          return value && value.size <= 3000000;
        }),
    }),
    onSubmit: (values: Report) => {
      setLoading(true);
      addNewReport(
        values.uid,
        values.reportType,
        values.file as File,
        values.name
      );
    },
    enableReinitialize: true,
  });

  const { handleChange, handleSubmit, values, errors } = formik;

  // show a human display for fiel size in MB or KB or GB

  const fileSize = (size: number) => {
    if (size >= 1000000) {
      return `${(size / 1000000).toFixed(2)} MB`;
    } else if (size >= 1000) {
      return `${(size / 1000).toFixed(2)} KB`;
    } else {
      return `${size} B`;
    }
  };

  React.useEffect(() => {
    if (values.reportType) {
      const reportType = userReportTypes?.find(
        // @ts-ignore
        (reportType: ReportType) => reportType.$id === values.reportType
      );
      formik.setFieldValue("dueDate", reportType?.dueDay);
      formik.setFieldValue("name", reportType?.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.reportType]);

  useAddReportSideEffect(setLoading, formik);

  return (
    <main className="flex flex-col items-center py-10">
      <Toast ref={toast}></Toast>

      <div className="flex flex-col items-center gap-5 container">
        <div className="w-full md:w-10/12 lg:w-7/12">
          <Title title="Add Report" />
          <FormikProvider value={formik}>
            <div className="flex flex-col gap-6 mt-5">
              <Select
                label="Report Type"
                id="reportType"
                name="reportType"
                options={userReportTypes?.map(
                  (reportType: Partial<ReportType>) => ({
                    label: reportType.name,
                    value: reportType.$id,
                  })
                )}
                filter
                onChange={handleChange}
              />

              <Input label="Due Date" id="dueDate" name="dueDate" readOnly />

              <div className="flex flex-col flex-wrap gap-2 w-full">
                <small className="p-help text-500">
                  Max file size: 3MB you can use this link to compress your pdf.
                  <br />
                  <a
                    href="https://smallpdf.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-green-800"
                  >
                    https://smallpdf.com/
                  </a>
                </small>

                <div className="flex flex-wrap items-center gap-3">
                  <FileUpload
                    mode="basic"
                    name="file"
                    accept=".pdf, .docx, .doc,.pptx, .ppt, .xlsx, .xls, .csv,"
                    maxFileSize={3000000}
                    onSelect={(e: FileUploadSelectEvent) => {
                      formik.setFieldValue("file", e.files[0]);
                      console.log(e.files[0]);
                    }}
                  />

                  <p>
                    {values.file && (
                      <small className="p-help text-500">
                        {fileSize(values.file.size)}
                      </small>
                    )}
                  </p>
                </div>
                {errors.file && (
                  <div>
                    {/* @ts-ignore */}
                    <small className="p-error">{errors?.file}</small>
                  </div>
                )}
              </div>

              <Button
                label="Submit"
                className="p-button-success"
                onClick={() => {
                  handleSubmit();
                }}
                loading={loading}
              />
            </div>
          </FormikProvider>
        </div>
      </div>
    </main>
  );
}
