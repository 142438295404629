import type { User } from "../../appwrite/types";

interface Props {
  data: User[];
}

export default function UsersList({ data }: Props) {
  return (
    <main>
      {data.map((_, i) => (
        <div
          key={i}
          className="flex items-center justify-between bg-white mb-5 p-5 rounded"
        >
          <div className="flex flex-col">
            <p>{_.name}</p>
            <p>{_.email}</p>
          </div>
        </div>
      ))}
    </main>
  );
}
