import React from "react";
import { useStore } from "@nanostores/react";
import { ActionTypes } from "../constants/actionTypes";
import { actionType, resetActionType } from "../appwrite/atom";

export const useLoginSideEffect = (setIsLoading: (arg0: boolean) => void) => {
  const actionTypeListener = useStore(actionType);

  React.useEffect(() => {
    if (actionTypeListener === ActionTypes.LOGIN_SUCCESS) {
      setIsLoading(false);
      resetActionType();
      window.location.reload();
    } else if (actionTypeListener === ActionTypes.LOGIN_ERROR) {
      setIsLoading(false);
      resetActionType();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionTypeListener]);
};
