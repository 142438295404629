import { Image } from "primereact/image";
import { Sidebar } from "react-pro-sidebar";
import { NavLink, useLocation } from "react-router-dom";
import { renderRouteByRole } from "./renderRouteByRole";
import { getUser } from "../../appwrite/atom";

interface Props {
  onMenuClick?: () => void;
}

export default function Side({ onMenuClick }: Props) {
  const location = useLocation();

  const activeStyle =
    "w-full p-3 gap-3 flex align-items-center cursor-pointer bg-white rounded-md text-primary";
  const style =
    "w-full p-3 gap-3 flex  text-white rounded-md align-items-center cursor-pointer hover:text-red-300 hover:bg-red-500";

  // check if current path contains a given parameter and return the style
  const isActive = (path: string) => {
    return location.pathname.includes(path) ? activeStyle : style;
  };

  const { role } = getUser();

  return (
    <Sidebar
      className="border-primary-500 h-full"
      backgroundColor="rgba(235, 28, 36, 01)"
      rootStyles={{
        border: "none",
        boxShadow: "none",
        width: 255,
        height: "100%",
        position: "fixed",
        zIndex: 5,
        top: 0,
        left: 0,
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "0",
        margin: "0",
      }}
    >
      <div className="flex align-items-center gap-2 px-4 py-[10px] border-round side">
        <Image src="/icon.svg" imageStyle={{ height: "2.5rem" }} />
        <h1 className="font-semibold text-white text-xl leading-tight">
          Management Repository
        </h1>
      </div>

      <div className="flex flex-col gap-2 mx-1 mt-3">
        {renderRouteByRole(role).map((route, index) => (
          <NavLink
            key={index}
            to={route.to}
            className={isActive(route.isActive)}
            onClick={onMenuClick}
          >
            {route.icon}
            <span>{route.label}</span>
          </NavLink>
        ))}
      </div>
    </Sidebar>
  );
}
