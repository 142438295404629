import React from "react";
import Title from "../../components/shared/Title";
import Loader from "../../components/shared/Loader";
import { useQueries } from "react-query";
import {
  getReportTypes,
  getUsers,
  addNewReportType,
  updateReportType,
} from "../../appwrite/functions";
import ReportList from "../../components/reports/ReportList";
import type { ReportType, User } from "../../appwrite/types";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import ReportModal from "../../components/reports/ReportModal";
import DueDayModal from "../../components/reports/DueDayModal";
import { useAddReportTypeSideEffect } from "../../hooks/useAddReportTypeSideEffect";

export default function ReportTypes() {
  const [show, toggleShow] = React.useReducer((s) => !s, false);
  const [showDueDay, toggleDueDay] = React.useReducer((s) => !s, false);
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const [dueDay, setDueDay] = React.useState<string>("24");

  const [reportTypes, users] = useQueries([
    {
      queryKey: "reportTypes",
      queryFn: getReportTypes,
    },
    {
      queryKey: "users",
      queryFn: getUsers,
    },
  ]);

  const data = reportTypes.data as ReportType[];
  const refetch = reportTypes.refetch;
  const userData = users.data as User[];
  const isLoading = reportTypes.isLoading || users.isLoading;

  const formik = useFormik({
    initialValues: {
      name: "",
      dueDay: "24",
      users: "",
      uid: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      dueDay: Yup.number().required("Due day is required"),
      users: Yup.string().required("User is required"),
    }),
    onSubmit: (values) => {
      console.log(values);
      setSubmitLoading(true);
      addNewReportType(values.name, values.dueDay, values.users, values.uid);
    },
    enableReinitialize: true,
  });

  React.useEffect(() => {
    if (formik.values.users) {
      const user = userData.find((user) => user.$id === formik.values.users);
      formik.setFieldValue("uid", user?.uid || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.users]);

  useAddReportTypeSideEffect(setSubmitLoading, formik, refetch);

  const changeDueDay = () => {
    // update due day for each report type
    setSubmitLoading(true);
    data.forEach((reportType) => {
      updateReportType(reportType.$id, dueDay);
    });
  };

  if (isLoading) {
    return (
      <main className="flex flex-col items-center justify-center py-10 min-h-[80dvh]">
        <Loader />
      </main>
    );
  }

  return (
    <main className="flex flex-col items-center py-10">
      <div className="flex flex-col items-center gap-5 container">
        <div className="flex items-center justify-between w-full md:w-10/12 lg:w-7/12">
          <Title title="Report Management" />
          <div className="flex gap-4">
            <Button
              className="p-button-sm"
              label="add new"
              icon="pi pi-plus"
              onClick={toggleShow}
            />

            <Button
              className="p-button-info p-button-sm"
              label="change due day"
              icon="pi pi-clock"
              onClick={toggleDueDay}
            />
          </div>
        </div>

        <div className="w-full md:w-10/12 lg:w-7/12">
          {isLoading ? (
            <div className="flex flex-col items-center justify-center my-10">
              <Loader />
              <p>Fetching Users...</p>
            </div>
          ) : (
            <ReportList data={data} />
          )}
        </div>
      </div>

      <ReportModal
        visible={show}
        onHide={toggleShow}
        title="Add Report Type"
        formik={formik}
        submitLoading={submitLoading}
        users={userData}
      />

      <DueDayModal
        visible={showDueDay}
        onHide={toggleDueDay}
        title="Change Due Day"
        submitLoading={submitLoading}
        dueDay={dueDay}
        setDueDay={setDueDay}
        changeDueDay={changeDueDay}
      />
    </main>
  );
}
