import React from "react";
import Table from "../../components/shared/Table";
import { mdDashColumns } from "../../constants/columns";
import { useNavigate } from "react-router-dom";
import { useQueries } from "react-query";
import { getUsers, getMonthsReports } from "../../appwrite/functions";
import { Report, ReportType, User } from "../../appwrite/types";
import Filter from "../../components/dashboard/Filter";
import Loader from "../../components/shared/Loader";

export default function Dashboard() {
  const navigate = useNavigate();
  const [userReportTypes, setUserReportTypes] = React.useState<ReportType[]>(
    []
  );
  const [filter, setFilter] = React.useState("");
  const currentMonthString = new Date().toLocaleString("default", {
    month: "long",
  });

  const [users, reports] = useQueries([
    {
      queryKey: "users",
      queryFn: getUsers,
    },
    {
      queryKey: "reports",
      queryFn: getMonthsReports,
    },
  ]);

  const usersData = users.data;
  const reportsData = reports.data;
  const isLoading = users.isLoading || reports.isLoading;

  if (isLoading) {
    return (
      <main className="flex flex-col items-center justify-center py-10 min-h-[80dvh]">
        <Loader />
      </main>
    );
  }

  return (
    <main className="flex flex-col items-center py-10">
      <div className="flex flex-col lg:items-center gap-5 container">
        <div className="flex justify-between mb-5 w-full lg:w-7/12">
          <h1 className="font-medium text-4xl">
            {currentMonthString} Report Overvue
          </h1>
        </div>

        <div className="flex gap-5 w-full lg:w-7/12">
          <Filter
            setFilter={setFilter}
            setUserReportTypes={setUserReportTypes}
            filter={filter}
            users={usersData as Partial<User>[]}
          />
        </div>

        <div className="mt-5 w-full lg:w-7/12 overflow-hidden">
          <Table
            data={userReportTypes}
            columns={mdDashColumns(
              navigate,
              reportsData as Report[],
              filter,
              userReportTypes as ReportType[]
            )}
            progressPending={isLoading}
            emptyIcon="pi-table"
            emptyMessage={
              filter === ""
                ? "Select a user to view reports"
                : "No reports available for this user"
            }
            progressComponent={<Loader />}
          />
        </div>
      </div>
    </main>
  );
}
