import React from "react";
import { Button } from "primereact/button";
import { getUser } from "../../appwrite/atom";
import { useLogoutSideEffect } from "../../hooks/useLogoutSideEffect";
import { logout } from "../../appwrite/functions";

interface Props {
  onMenu?: () => void;
}

export default function NavBar({ onMenu }: Props) {
  const { name } = getUser();
  const [loading, setLoading] = React.useState(false);
  useLogoutSideEffect(setLoading);

  return (
    <header className="top-0 z-10 sticky bg-white shadow px-4 py-3 header">
      <div className="flex flex-row items-center header-content">
        <div className="flex items-center gap-2">
          <Button
            icon="pi pi-bars"
            className="flex p-button-sm lg:hidden"
            onClick={onMenu}
          />
          <h1 className="font-semibold text-primary text-xl">{name}</h1>
        </div>
        <div className="flex ml-auto">
          <Button
            className="p-button-sm"
            icon="pi pi-sign-out"
            label="Logout"
            onClick={() => {
              setLoading(true);
              logout();
            }}
            loading={loading}
          />
        </div>
      </div>
    </header>
  );
}
