import React from "react";
import AuthLayout from "../../layout/AuthLayout";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import Input from "../../components/forms/Input";
import Password from "../../components/forms/Password";
import { Button } from "primereact/button";
import { login } from "../../appwrite/functions";
import { useLoginSideEffect } from "../../hooks/useLoginSideEffect";
import logo from "@assets/brand/logo.svg";

export default function Login() {
  const [isLoading, setIsLoading] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: (values) => {
      setIsLoading(true);
      login(values.email, values.password);
    },
  });

  const { handleSubmit } = formik;

  useLoginSideEffect(setIsLoading);

  return (
    <AuthLayout>
      <div className="z-10 grid place-items-center gap-5 bg-white px-5 md:px-10 py-10 w-10/12 md:w-9/12 lg:w-5/12 rounded">
        <div className="flex flex-col items-center gap-3">
          <img src={logo} alt="logo" className="mx-auto w-[10rem]" />
          <h1 className="font-semibold text-2xl text-center md:text-3xl">
            Management Repository Login
          </h1>
        </div>

        <FormikProvider value={formik}>
          <div className="flex flex-col gap-6 my-8 w-11/12 lg:w-10/12">
            <Input
              icon="pi pi-envelope"
              label="Email"
              id="email"
              placeholder="example@company.com"
              isRequired
            />

            <Password
              label="Password"
              id="password"
              placeholder="Enter your password"
              isRequired
              lockicon
              feedback={false}
            />

            <Button
              label="LOGIN"
              loading={isLoading}
              className="mt-6 w-full"
              onClick={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            />
          </div>
        </FormikProvider>
      </div>
    </AuthLayout>
  );
}
