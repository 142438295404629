import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

interface Props {
  visible: boolean;
  onHide: () => void;
  title: string;
  submitLoading?: boolean;
  changeDueDay: () => void;
  dueDay : string;
  setDueDay: (value: string) => void; 
}

export default function DueDayModal({
  visible,
  onHide,
  title,
  submitLoading,
  dueDay,
  setDueDay,
  changeDueDay,
}: Props) {
  return (
    <Dialog
      header={title}
      visible={visible}
      style={{ width: "50vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      onHide={onHide}
    >
      <div className="flex flex-col gap-5 w-full">
        <div className="flex flex-col gap-2">
          <label htmlFor="dueDay" className="text-gray-500">
            Due Day
          </label>
          <InputText
            id="dueDay"
            name="dueDay"
            value = {dueDay}
            onChange={(e) => {
              setDueDay(e.target.value);
            }}
            className="w-full"
          />
        </div>
        <Button
          loading={submitLoading}
          className="mt-5 p-button-success"
          onClick={(e) => {
            e.preventDefault();
            changeDueDay();
          }}
        >
          Submit
        </Button>
      </div>
    </Dialog>
  );
}
