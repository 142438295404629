import { Account, Client, Databases, Storage } from "appwrite";

export const client = new Client()
	.setEndpoint("https://cloud.appwrite.io/v1")
	.setProject("65c9fe1f5aec18056235");

export const DATABASE_ID = "mrdb";
export const USER_COLLECTION = "users";
export const REPORT_TYPES_COLLECTION = "reportTypes";
export const REPORTS_COLLECTION = "reportSubmissions";

export const account = new Account(client);
export const database = new Databases(client);
export const storage = new Storage(client);

export const s3config = {
	bucketName: "mngrepo",
	dirName: "repository",
	region: "eu-west-3",
	accessKeyId: "AKIA2Q4VR7S3NJW4BHEQ",
	secretAccessKey: "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",
};

