import React from "react";
import { useStore } from "@nanostores/react";
import { ActionTypes } from "../constants/actionTypes";
import { actionType, resetActionType } from "../appwrite/atom";
import { toast } from "react-toastify";

export const useAddReportSideEffect = (
  setIsLoading: (arg0: boolean) => void,
  formik: { resetForm: () => void; }
) => {
  const actionTypeListener = useStore(actionType);

  React.useEffect(() => {
    if (actionTypeListener === ActionTypes.ADD_REPORT_SUCCESS) {
      setIsLoading(false);
      resetActionType();
      formik.resetForm();
      toast.success("Report added successfully");
    } else if (actionTypeListener === ActionTypes.ADD_REPORT_ERROR) {
      setIsLoading(false);
      resetActionType();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionTypeListener]);
};
