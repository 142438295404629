import React from "react";
import { ReportType, User } from "../../appwrite/types";
import { Button } from "primereact/button";

interface Props {
  setFilter: React.Dispatch<React.SetStateAction<string>>;
  setUserReportTypes: React.Dispatch<React.SetStateAction<ReportType[]>>;
  filter: string;
  users: Partial<User>[];
}

export default function Filter({
  setFilter,
  filter,
  users,
  setUserReportTypes,
}: Props) {
  return (
    <div className={`flex flex-wrap gap-2`}>
      {users?.map((user) => (
        <Button
          disabled={user.name === "Admin" || user.name === "Managing Director"}
          key={user.$id}
          onClick={() => {
            setFilter(user.name);
            setUserReportTypes(user.reportTypes as unknown as ReportType[]);
          }}
          className={`p-button-sm rounded-full text-nowrap 
          ${
            user.name === "Admin" || user.name === "Managing Director"
              ? "hidden"
              : "block"
          }
          ${
            filter === user.name
              ? "bg-brand text-white"
              : " bg-white border-gray-300 text-brand"
          }`}
        >
          {user.name}
        </Button>
      ))}
    </div>
  );
}
