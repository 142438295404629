export const ROLES = {
  ADMIN: "admin",
  USER: "user",
  MD: "md",
};

export const SESSION = {
  ACTIVE: "active",
  INACTIVE: "inactive",
};

export const Months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
