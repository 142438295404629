import DashCard from "../../components/dashboard/DashCard";
import Table from "../../components/shared/Table";
import { dashColumns } from "../../constants/columns";
import { useNavigate } from "react-router-dom";
import {
  getMonthsReportsByUser,
  getUserReportTypes,
} from "../../appwrite/functions";
import { useQueries } from "react-query";
import { Report, ReportType } from "../../appwrite/types";
import Loader from "../../components/shared/Loader";
import { getUser } from "../../appwrite/atom";

export default function Dashboard() {
  const navigate = useNavigate();
  const { uid } = getUser();
  const currentMonthString = new Date().toLocaleString("default", {
    month: "long",
  });

  // const { data, isLoading } = useQuery("getMonthsReports", () =>
  //   getMonthsReports(uid)
  // );

  const [userMonthlyReports, userReportTypes] = useQueries([
    {
      queryKey: "userMonthlyReports",
      queryFn: () => getMonthsReportsByUser(uid),
    },
    {
      queryKey: "userReportTypes",
      queryFn: () => getUserReportTypes(uid),
    },
  ]);

  const data = userReportTypes.data as ReportType[];
  const isLoading = userMonthlyReports.isLoading || userReportTypes.isLoading;
  const monthReports = userMonthlyReports.data as Report[];

  if (isLoading) {
    return (
      <main className="flex flex-col items-center justify-center py-10 min-h-[80dvh]">
        <Loader />
      </main>
    );
  }

  return (
    <main className="flex flex-col items-center py-10">
      <div className="flex flex-col lg:items-center gap-5 container">
        <div className="flex justify-between mb-5 w-full lg:w-9/12">
          <h1 className="font-medium text-4xl">
            {currentMonthString} Report Overvue
          </h1>
        </div>

        <div className="flex gap-5 w-full lg:w-9/12">
          <DashCard
            value={data?.length}
            label="Total Reports"
            icon="pi pi-table"
          />
        </div>

        <div className="mt-5 w-full lg:w-9/12 overflow-hidden">
          <Table
            data={data as ReportType[]}
            columns={dashColumns(navigate, monthReports)}
            emptyIcon="pi-folder"
            emptyMessage="No reports found for this month."
            progressPending={isLoading}
            progressComponent={<Loader />}
          />
        </div>
      </div>
    </main>
  );
}
