import React from "react";
import { useStore } from "@nanostores/react";
import { ActionTypes } from "../constants/actionTypes";
import { actionType, resetActionType } from "../appwrite/atom";
import { toast } from "react-toastify";

export const useAddReportTypeSideEffect = (
  setSubmitLoading,
  formik,
  refetch
) => {
  const actionTypeListener = useStore(actionType);
  console.log(actionTypeListener);

  React.useEffect(() => {
    if (actionTypeListener === ActionTypes.ADD_REPORT_TYPE_SUCCESS) {
      setSubmitLoading(false);
      toast.success("Report Type added successfully");
      formik.resetForm();
      resetActionType();
      refetch();
    } else if (actionTypeListener === ActionTypes.ADD_REPORT_TYPE_ERROR) {
      setSubmitLoading(false);
      toast.error("Error adding report type");
      resetActionType();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionTypeListener]);
};
