import { persistentAtom } from "@nanostores/persistent";
import type { User } from "./types";
import { atom } from "nanostores";

// persist session
export const session = persistentAtom<"active" | "inactive">(
  "dlcl_mr_session",
  "inactive",
  {
    encode: JSON.stringify,
    decode: JSON.parse,
  }
);

// user store
export const user$ = persistentAtom<undefined | Partial<User>>(
  "dlcl_mr_user",
  undefined,
  {
    encode: JSON.stringify,
    decode: JSON.parse,
  }
);

// action type store
export const actionType = atom<string>("");

export function getActionType(): string {
  return actionType.get();
}

export function setActionType(value: string): void {
  actionType.set(value);
}

export function resetActionType(): void {
  actionType.set("");
}

export function getSession(): string {
  return session.get();
}

export function setSession(value: "active" | "inactive"): void {
  session.set(value);
}

export function getUser(): undefined | Partial<User> {
  const user = user$.get();
  if (!user) {
    user$.set(undefined);
  }
  return user;
}

export function storeUser(user: User): void {
  user$.set(user);
}

export function setUser(user: User): void {
  user$.set(user);
}

export function clearUser(): void {
  user$.set(undefined);
}
