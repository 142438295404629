import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import React from "react";

export default function TableMenu({
  onView,
  onAdd,
}: Partial<{
  onView: () => void;
  onAdd: () => void;
}>) {
  const menu = React.useRef<Menu>(null);

  const items = [
    {
      label: "View Report",
      icon: "pi pi-fw pi-eye",
      command: () => onView(),
    },
    {
      label: "Add Report",
      icon: "pi pi-fw pi-pencil",
      command: () => onAdd(),
    },
  ];

  return (
    <React.Fragment>
      <Button
        className="p-button-text "
        icon="pi pi-ellipsis-v"
        onClick={(event) => menu.current?.toggle(event)}
      />

      <Menu ref={menu} model={items} popup />
    </React.Fragment>
  );
}

export const MdTableMenu = ({
  onView,
}: Partial<{
  onView: () => void;
}>) => {
  const menu = React.useRef<Menu>(null);

  const items = [
    {
      label: "View Report",
      icon: "pi pi-fw pi-eye",
      command: () => onView(),
    },
  ];

  return (
    <React.Fragment>
      <Button
        className="p-button-text "
        icon="pi pi-ellipsis-v"
        onClick={(event) => menu.current?.toggle(event)}
      />

      <Menu ref={menu} model={items} popup />
    </React.Fragment>
  );
};
