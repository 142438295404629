import { createBrowserRouter, Navigate } from "react-router-dom";
import Login from "../pages/auth/Login";

export const AuthRouter = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },

  {
    path: "*",
    element: <Navigate to="/" replace />,
  },
]);
