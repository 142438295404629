import { AppwriteException, Query, ID } from "appwrite";

import {
  account,
  database,
  //   storage,
  DATABASE_ID,
  USER_COLLECTION,
  REPORT_TYPES_COLLECTION,
  REPORTS_COLLECTION,
  s3config,
} from "./config";
import { uploadFile } from "react-s3";

import { clearUser, getUser, session, setActionType, user$ } from "./atom";
import { toast } from "react-toastify";
import { ActionTypes } from "../constants/actionTypes";
import { getMonthNumber } from "../utils/date/getMonthNumber";

import { Buffer } from "buffer";
window.Buffer = window.Buffer || Buffer;

/***
 * appEntry -> This function is called when the app loads at the app.tsx
 * The function checks if the user has a session and sets the session to active else it sets the session to inactive
 * @returns void
 * **/

export const appEntry = async (): Promise<void> => {
  try {
    await account.getSession("current").then(async (response) => {
      if (response.current && user$.get().role !== undefined) {
        session.set("active");
      } else {
        setActionType(ActionTypes.SESSION_EXPIRED);
        session.set("inactive");
        user$.set(undefined);
      }
    });
  } catch (error) {
    const appwriteError = error as AppwriteException;
    console.error(appwriteError);
    toast.error(`Your session has expired, Please Login Again`);
    session.set("inactive");
  }
};

/***
 * getAccount -> This function gets the appwrite account details of the user
 * ?? might not need this function
 * ***/

export const getAccount = async () => {
  try {
    return account.get();
  } catch (error) {
    const appwriteError = error as AppwriteException;
    toast.error(appwriteError.message);
  }
};

/****
 * login -> This function logs in the user
 * ***/

export const login = async (email: string, password: string) => {
  try {
    await account.createEmailSession(email, password).then(async (response) => {
      console.log(response);
      await getUserById(response.userId).then((user) => {
        if (user) {
          user$.set(user);
          session.set("active");
          toast.success("Login Successful");
          setActionType(ActionTypes.LOGIN_SUCCESS);
        } else {
          toast.error("User not found");
          session.set("inactive");
          setActionType(ActionTypes.LOGIN_ERROR);
        }
      });
    });
  } catch (error) {
    const appwriteError = error as AppwriteException;
    toast.error(appwriteError.message);
    setActionType(ActionTypes.LOGIN_ERROR);
  }
};

/***
 * logout -> This function logs out the user
 * ***/

export const logout = async () => {
  try {
    const user = getUser();
    if (user?.$id) {
      await account.deleteSession("current");
      session.set("inactive");
      clearUser();
      setActionType(ActionTypes.LOGOUT_SUCCESS);
    }
  } catch (error) {
    const appwriteError = error as AppwriteException;
    setActionType(ActionTypes.LOGOUT_ERROR);
    toast.error(appwriteError.message);
  }
};

/***
 * getUserById -> this function queries for the user informaiton by the user id after session
 * ***/

export const getUserById = async (id: string) => {
  try {
    const user = await database.listDocuments(DATABASE_ID, USER_COLLECTION, [
      Query.equal("uid", id),
    ]);

    return user.documents[0];
  } catch (error) {
    const appwriteError = error as AppwriteException;
    toast.error(appwriteError.message);
  }
};

/****
 * getUser -> This function gets all users
 * ***/

export const getUsers = async () => {
  try {
    const users = await database.listDocuments(DATABASE_ID, USER_COLLECTION);
    return users.documents;
  } catch (error) {
    const appwriteError = error as AppwriteException;
    toast.error(appwriteError.message);
  }
};

/****
 * getReportTypes -> This function gets all reportTypes
 * ***/

export const getReportTypes = async () => {
  try {
    const reportTypes = await database.listDocuments(
      DATABASE_ID,
      REPORT_TYPES_COLLECTION,
      [Query.limit(100)]
    );

    console.log(reportTypes);

    return reportTypes.documents;
  } catch (error) {
    const appwriteError = error as AppwriteException;
    toast.error(appwriteError.message);
  }
};

/***
 * getMonthReports -> This function gets all reports
 * ***/
export const getMonthsReports = async () => {
  try {
    const firstDateOfCurrentMonth = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      1
    );
    const lastDateOfCurrentMonth = new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 1,
      0
    );

    const reports = await database.listDocuments(
      DATABASE_ID,
      REPORTS_COLLECTION,
      [
        Query.greaterThan(
          "submissionDate",
          firstDateOfCurrentMonth.toISOString()
        ),
        Query.lessThan("submissionDate", lastDateOfCurrentMonth.toISOString()),
      ]
    );

    return reports.documents;
  } catch (error) {
    const appwriteError = error as AppwriteException;
    toast.error(appwriteError.message);
  }
};

/***
 * addNewReportType -> This function adds a new report type
 * ***/

export const addNewReportType = async (
  name: string,
  dueDay: string,
  users: string,
  uid: string
) => {
  try {
    await database
      .createDocument(DATABASE_ID, REPORT_TYPES_COLLECTION, ID.unique(), {
        name,
        dueDay,
        users,
        uid,
      })
      .then((response) => {
        if (response.$id) {
          setActionType(ActionTypes.ADD_REPORT_TYPE_SUCCESS);
        } else {
          setActionType(ActionTypes.ADD_REPORT_TYPE_ERROR);
        }
      });
  } catch (error) {
    const appwriteError = error as AppwriteException;
    setActionType(ActionTypes.ADD_REPORT_TYPE_ERROR);
    toast.error(appwriteError.message);
  }
};

/****
 * updateReportType
 * ***/

export const updateReportType = async (id: string, dueDay: string) => {
  try {
    await database
      .updateDocument(DATABASE_ID, REPORT_TYPES_COLLECTION, id, {
        dueDay,
      })
      .then((response) => {
        if (response.$id) {
          setActionType(ActionTypes.ADD_REPORT_TYPE_SUCCESS);
        } else {
          setActionType(ActionTypes.ADD_REPORT_TYPE_ERROR);
        }
      });
  } catch (error) {
    const appwriteError = error as AppwriteException;
    setActionType(ActionTypes.ADD_REPORT_TYPE_ERROR);
    toast.error(appwriteError.message);
  }
};

/****
 * getUserReportTypes -> This function gets all report types for a user
 * ****/

export const getUserReportTypes = async (uid: string) => {
  try {
    const reportTypes = await database.listDocuments(
      DATABASE_ID,
      REPORT_TYPES_COLLECTION,
      [Query.equal("uid", uid)]
    );
    return reportTypes.documents;
  } catch (error) {
    const appwriteError = error as AppwriteException;
    toast.error(appwriteError.message);
  }
};

/***
 * getMonthsReports -> This function gets all reports for the current month
 * ***/

export const getMonthsReportsByUser = async (uid: string) => {
  try {
    const firstDateOfCurrentMonth = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      1
    );
    const lastDateOfCurrentMonth = new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 1,
      0
    );

    const reports = await database.listDocuments(
      DATABASE_ID,
      REPORTS_COLLECTION,
      [
        Query.greaterThan(
          "submissionDate",
          firstDateOfCurrentMonth.toISOString()
        ),
        Query.lessThan("submissionDate", lastDateOfCurrentMonth.toISOString()),
        Query.equal("uid", uid),
      ]
    );

    return reports.documents;
  } catch (error) {
    const appwriteError = error as AppwriteException;
    toast.error(appwriteError.message);
  }
};

/****
 *
 *  addNewReport -> This function adds a new report
 * *****/

export const addNewReport = async (
  uid: string,
  reportTypes: string,
  file: File,
  name: string
) => {
  try {
    const upload = await uploadFile(file, s3config);
    const fileUrl = upload.location.replace(/ /g, "+");

    if (upload.location) {
      await database
        .createDocument(DATABASE_ID, REPORTS_COLLECTION, ID.unique(), {
          uid,
          reportTypes,
          fileUrl,
          submitted: true,
          submissionDate: new Date().toISOString(),
          name,
        })
        .then((response) => {
          if (response.$id) {
            setActionType(ActionTypes.ADD_REPORT_SUCCESS);
          } else {
            setActionType(ActionTypes.ADD_REPORT_ERROR);
          }
        });
    } else {
      setActionType(ActionTypes.ADD_REPORT_ERROR);
    }
  } catch (error) {
    const appwriteError = error as AppwriteException;
    setActionType(ActionTypes.ADD_REPORT_ERROR);
    toast.error(appwriteError.message);
  }
};

/*****
 * getReportByNameForPeriod -> This function gets all reports for a user by name for a period less than the date parameter
 * ***/

export const getReportByNameForPeriod = async (
  year: string,
  month: string,
  name: string
) => {
  try {
    const firstDateOfCurrentMonth = new Date(
      new Date(Number(year), getMonthNumber(month), 1)
    );
    const lastDateOfCurrentMonth = new Date(
      new Date(Number(year), getMonthNumber(month) + 1, 0)
    );

    const reports = await database.listDocuments(
      DATABASE_ID,
      REPORTS_COLLECTION,
      [
        Query.greaterThan(
          "submissionDate",
          firstDateOfCurrentMonth.toISOString()
        ),
        Query.lessThan("submissionDate", lastDateOfCurrentMonth.toISOString()),
        Query.equal("name", name),
      ]
    );

    if (reports.documents.length === 0) {
      toast.info("No report found for the selected period");
      setActionType(ActionTypes.GET_REPORTS_ERROR);
    } else {
      setActionType(ActionTypes.GET_REPORTS_SUCCESS);
    }

    return reports.documents;
  } catch (error) {
    const appwriteError = error as AppwriteException;
    toast.error(appwriteError.message);
    setActionType(ActionTypes.GET_REPORTS_ERROR);
  }
};
