interface Props {
  value: number;
  label: string;
  icon: string;
  color?: string;
}


export default function DashCard({
  value,
  label,
  icon,
  color = "green",
}: Props) {
  return (
    <div className="flex items-center justify-center gap-3 bg-white shadow-md p-5 rounded-lg">
      <div
        className={`flex items-center justify-center bg-${color}-100 rounded-full w-12 h-12`}
      >
        <i className={`${icon} text-2xl text-${color}-500`}></i>
      </div>
      <h1 className="font-bold text-4xl">{value}</h1>
      <div className="flex flex-col">
        <p className="text-gray-500">{label}</p>
      </div>
    </div>
  );
}
