import { createBrowserRouter, Navigate } from "react-router-dom";
import Layout from "../layout/AppLayout";
import Dashboard from "../pages/app/Dashboard";
import Reports from "../pages/app/Reports";
import Users from "../pages/app/Users";
import AddReport from "../pages/app/AddReport";
import MdDashboard from "../pages/app/MdDashboard";
import ReportTypes from "../pages/app/ReportTypes";
import MdReports from "../pages/app/MdReports";

export const AppRouter = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "md-dashboard",
        element: <MdDashboard />,
      },
      {
        path: "reports",
        element: <Reports />,
      },
      {
        path: "md-reports",
        element: <MdReports />,
      },
      {
        path: "add-report",
        element: <AddReport />,
      },
      {
        path: "users",
        element: <Users />,
      },
      {
        path: "report-types",
        element: <ReportTypes />,
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/dashboard" replace />,
  },
]);
