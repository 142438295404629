import React from "react";
import Title from "../../components/shared/Title";
import { Months } from "../../constants/options";
import { FormikProvider, useFormik } from "formik";
import YearSelector from "../../components/reports/YearSelector";
import MonthSelector from "../../components/reports/MonthSelector";
import ReportSelector from "../../components/reports/ReportSelector";
import ReportViewer from "../../components/reports/ReportViewer";
import { getUser } from "../../appwrite/atom";
import { Button } from "primereact/button";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import {
  getReportByNameForPeriod,
  getUserReportTypes,
} from "../../appwrite/functions";
import { Report, ReportType } from "../../appwrite/types";
import { useGetReportSideEffect } from "../../hooks/useGetReportSideEffect";

export default function Reports() {
  const { state } = useLocation();
  const location = useLocation();
  const navigate = useNavigate();

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().toLocaleString("default", { month: "short" });
  const { role, uid } = getUser();
  const [currentReport, setCurrentReport] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);

  const { data } = useQuery("getUserReportTypes", () =>
    getUserReportTypes(uid)
  );

  const formik = useFormik({
    initialValues: {
      year: currentYear.toString(),
      month: currentMonth,
      reportType: state?.reportType ?? "",
    },
    onSubmit: async (values) => {
      setLoading(true);

      const report = (await getReportByNameForPeriod(
        values.year,
        values.month,
        values.reportType
      )) as Report[];

      if (report.length > 0) {
        setCurrentReport(report[0]?.fileUrl);
      } else if (report.length === 0) {
        setCurrentReport("");
      }
    },
    enableReinitialize: true,
  });

  const years = ["2024", "2025"];
  const months = Months;

  const { values } = formik;

  useGetReportSideEffect(setLoading);

  return (
    <main className="flex flex-col items-center py-10">
      <div className="flex flex-col lg:items-center gap-5 container">
        <div className="flex justify-between w-full lg:w-9/12">
          <Title title="Reports" />

          <Button
            label="Reset"
            icon="pi pi-refresh"
            className="p-button-outlined p-button-sm "
            onClick={() => {
              formik.resetForm();
              setCurrentReport("");
              navigate(location.pathname, { replace: true });
            }}
          />
        </div>
        <FormikProvider value={formik}>
          <div className="flex flex-col gap-5 lg:w-9/12">
            <YearSelector years={years} id="year" />
            <MonthSelector months={months} id="month" />
            <ReportSelector
              reportTypes={data as ReportType[]}
              setCurrentReport={setCurrentReport}
              id="reportType"
            />

            <Button
              label="Fetch Report"
              disabled={
                !values.reportType || !values.year || !values.month || loading
              }
              onClick={formik.submitForm}
              className="p-button-info"
            />

            <ReportViewer
              loading={loading}
              url={currentReport as string}
              role={role}
            />
          </div>
        </FormikProvider>
      </div>
    </main>
  );
}
