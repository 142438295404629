import loading from "../../assets/loader.gif";
import { Image } from "primereact/image";

const Loader = ({ text }: { text?: string }) => {
  return (
    <div className="flex align-items-center justify-content-center">
      <Image
        src={loading}
        alt="loading ..."
        style={{
          height: "70px",
          width: "70px",
        }}
      />

      <div className="mt-5 text-center">
        <h4>{text}</h4>
      </div>
    </div>
  );
};

export default Loader;
