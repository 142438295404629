import { Dropdown, DropdownProps } from "primereact/dropdown";
import React from "react";
import { useField } from "formik";
import { classNames } from "primereact/utils";

interface Props {
  id: string;
  label?: string;
  isRequired?: boolean;
  helperText?: string;
}

type SelectProps = Props & DropdownProps;

const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  (props) => {
    const { id, label, isRequired, helperText } = props;
    const [field, meta] = useField(id);
    const { value, onBlur, onChange } = field;
    const { error, touched } = meta;

    return (
      <div className="flex flex-col gap-2 p-fluid col">
        <label
          htmlFor={id}
          className={classNames({
            "p-error": touched && error,
          })}
        >
          {label} {isRequired && <span className="p-error">*</span>}
        </label>

        <Dropdown
          {...props}
          inputId={id}
          name={id}
          onBlur={onBlur(id)}
          value={value}
          onChange={(e) => {
            onChange({
              target: {
                id,
                value: e.value,
              },
            });
          }}
          className={classNames({
            "p-invalid": touched && error,
          })}
        />
        {helperText && <small className="p-help text-500">{helperText}</small>}
        {touched && error && <small className="p-error">{error}</small>}
      </div>
    );
  }
);

Select.displayName = "Select";
export default Select;
