import { useField } from "formik";
import { Button } from "primereact/button";
import { ReportType, User } from "../../appwrite/types";

interface Props {
  users: Partial<User>[] | null;
  id: string;
  extraId?: string;
}

export default function UserSelector({ users, id, extraId }: Props) {
  const [field, meta, helpers] = useField(id);

  const { value, onChange } = field;
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { error, touched } = meta;

  return (
    <div className="flex flex-col gap-2 bg-white px-5 py-3 rounded">
      <label htmlFor={id} className="text-gray-500">
        Select User
      </label>

      <div className="flex flex-wrap gap-3">
        {users?.map((user: Partial<User>, index) => {
          return (
            <Button
              disabled={
                user.name === "Admin" || user.name === "Managing Director"
              }
              className={`p-button-sm 
              ${
                user.name === "Admin" || user.name === "Managing Director"
                  ? "hidden"
                  : "block"
              }
              ${
                value === user.name
                  ? "bg-brand text-white"
                  : " bg-white border-gray-300 text-brand"
              }
              `}
              label={user.name}
              key={index}
              onClick={() => {
                helpers.setValue(user.name);
                onChange({
                  target: {
                    name: extraId,
                    value: user.reportTypes as unknown as ReportType[],
                  },
                });
              }}
            />
          );
        })}
      </div>
    </div>
  );
}
