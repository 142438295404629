export const tableTheme = {
  table: {
    style: {
      backgroundColor: "transparent",
    },
  },
  header: {
    style: {
      minHeight: "56px",
    },
  },
  headRow: {
    style: {
      borderColor: `#ec1c23`,
      backgroundColor: "#ec1c23",
      color: "white",
      fontWeight: "bold",
    },
  },
  headCells: {
    style: {
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "transparent",
      },
      fontFamily: "Outfit",
      fontSize: "14px",
    },
  },
  cells: {
    style: {
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "transparent",
        borderRadius: 0,
      },
      fontFamily: "Outfit",
      backgroundColor: "#FFFFFF",
    },
  },
  rows: {
    style: {
      "&:hover": {
        borderRadius: 0,
      },
    },
  },
};
