import { session } from "../appwrite/atom";
import { useStore } from "@nanostores/react";
import { useQuery } from "react-query";
import { appEntry } from "../appwrite/functions";

export const useAppEntry = () => {
  const sessionListener = useStore(session);

  useQuery("appEntry", appEntry);

  return sessionListener;
};
