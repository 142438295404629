import React from "react";
import { InputText, InputTextProps } from "primereact/inputtext";
import { useField } from "formik";
import { classNames } from "primereact/utils";

interface Props {
  id: string;
  label?: string;
  isRequired?: boolean;
  icon?: string;
  helperText?: string;
}

type InputProps = Props & InputTextProps;

const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { id, label, isRequired, icon, helperText } = props;

  const [field, meta] = useField(id);
  const { value, onBlur, onChange } = field;
  const { error, touched } = meta;

  return (
    <div className="flex flex-col gap-2 w-full">
      <label
        htmlFor={id}
        className={classNames({
          "p-error": touched && error,
        })}
      >
        {label} {isRequired && <span className="p-error">*</span>}
      </label>

      <span className={`${!!icon && "p-input-icon-left"}`}>
        <i className={icon} />
        <InputText
          ref={ref}
          {...props}
          value={value}
          onChange={(e) => {
            onChange(id)(e.target.value);
          }}
          onBlur={onBlur(id)}
          className={classNames({
            "p-invalid": touched && error,
            "w-full": true
          })}
        />
      </span>
      {helperText && <small className="p-help text-500">{helperText}</small> }
      {touched && error && <small className="p-error">{error}</small>}
    </div>
  );
});

Input.displayName = "Input";
export default Input;
