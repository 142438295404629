import React from "react";
import login from '@assets/backgrounds/login.jpg'
interface Props {
  children: React.ReactNode;
}

export default function AuthLayout(props: Props) {
  return (
    <main
      className="grid place-items-center min-h-[100dvh]"
      style={{
        // background: "linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)",
        background: `url(${login})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="absolute inset-0 bg-opacity-10 backdrop-blur"></div>
      {props.children}
    </main>
  );
}
