import type { ReportType } from "../../appwrite/types";
import If from "../shared/If";

interface Props {
  data: ReportType[];
}

export default function ReportList({ data }: Props) {
  return (
    <div>
      <If
        condition={data?.length === 0}
        then={
          <div className="flex flex-col items-center justify-center my-10">
            No reports found
          </div>
        }
        else={
          <div>
            {data.map((_: Partial<ReportType>, i) => (
              <div
                key={i}
                className="flex items-center justify-between bg-white mb-5 p-5 rounded"
              >
                <div className="flex flex-col gap-3 item-start">
                  <p className="text-xl">{_?.name}</p>
                 <div className='flex gap-4'>
                 <span className="flex items-center gap-2 bg-gray-500 px-4 py-1 rounded-xl text-white">
                   <i className='pi pi-user'/> <p className='text-sm'>{_.users?.name}</p>
                  </span>
                  <span className="flex bg-blue-400 px-4 py-1 rounded-xl text-white">
                  <p className="text-sm">Due day {_?.dueDay}</p>
                  </span>
                 </div>
                 
                </div>
              </div>
            ))}
          </div>
        }
      />
    </div>
  );
}
