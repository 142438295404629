import { useField } from "formik";
import { Button } from "primereact/button";

interface Props {
  years: string[] | null;
  id: string;
}

export default function YearSelector({ years, id }: Props) {
  const [field, meta, helpers] = useField(id);

  const { value } = field;
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { error, touched } = meta;

  return (
    <div className="flex flex-col gap-2 bg-white px-5 py-3 rounded">
      <label htmlFor={id} className="text-gray-500">
        Select Year
      </label>

      <div className="flex flex-wrap gap-3">
        {years?.map((year, index) => {
          return (
            <Button
              className={`p-button-sm ${
                value === year
                  ? "bg-brand text-white"
                  : " bg-white border-gray-300 text-brand"
              }
              `}
              label={year}
              key={index}
              onClick={() => {
                helpers.setValue(year);
              }}
            />
          );
        })}
      </div>
    </div>
  );
}
