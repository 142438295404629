import { useField } from "formik";
import { Button } from "primereact/button";
import { ReportType } from "../../appwrite/types";
import If from "../shared/If";

interface Props {
  reportTypes: ReportType[] | null;
  id: string;
  setCurrentReport: React.Dispatch<React.SetStateAction<string>>;
}

export default function ReportSelector({
  reportTypes,
  id,
  setCurrentReport,
}: Props) {
  const [field, meta, helpers] = useField(id);

  const { value } = field;
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { error, touched } = meta;

  return (
    <div className="flex flex-col gap-2 bg-white px-5 py-3 rounded">
      <label htmlFor={id} className="text-gray-500">
        Select Report
      </label>

      <div>
        <If
          condition={reportTypes?.length > 0}
          then={
            <div className="flex flex-wrap gap-3">
              {reportTypes?.map((report: ReportType, index) => {
                return (
                  <Button
                    disabled={value === report.name}
                    
                    className={`p-button-sm ${
                      value === report.name
                        ? "bg-brand text-white"
                        : " bg-white border-gray-300 text-brand"
                    }
    `}
                    label={report.name}
                    key={index}
                    onClick={() => {
                      helpers.setValue(report.name);
                      setCurrentReport("");
                    }}
                  />
                );
              })}
            </div>
          }
          else={
            <div className="flex flex-col items-center p-5 text-center">
              <i className="text-5xl text-gray-300 pi pi-folder-open"></i>
              <p> There are no reports available for this user</p>
            </div>
          }
        />
      </div>
    </div>
  );
}
