import React from "react";
import ReactDOM from "react-dom/client";
import App from "@/App";
import { ToastContainer } from "react-toastify";
import { PrimeReactProvider } from "primereact/api";
import { QueryClient, QueryClientProvider } from "react-query";
import reportWebVitals from "./reportWebVitals";

import "react-toastify/dist/ReactToastify.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "@/styles/index.css";
import "@/styles/theme.css";

const value = {
  ripple: true,
  cssTransition: true,
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: 1 * 60 * 60 * 1000,
      staleTime: 1 * 60 * 60 * 1000,
      retry: 1,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <PrimeReactProvider value={value}>
      <QueryClientProvider client={queryClient}>
        <ToastContainer
          position="top-center"
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick
          draggable
          theme="colored"
          style={{
            fontFamily: "Outfit, sans-serif",
          }}
        />
        <App />
      </QueryClientProvider>
    </PrimeReactProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
