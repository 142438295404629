import Title from "../../components/shared/Title";
import UsersList from "../../components/users/UsersList";
import { useQuery } from "react-query";
import { getUsers } from "../../appwrite/functions";
import type { User } from "../../appwrite/types";
import Loader from "../../components/shared/Loader";

export default function Users() {
  const { data, isLoading } = useQuery<User[], Error>("users", () => {
    return getUsers() as Promise<User[]>;
  });




  return (
    <main className="flex flex-col items-center py-10">
      <div className="flex flex-col items-center gap-5 container">
       <Title title='Users'/>

        <div className="w-full md:w-10/12 lg:w-7/12">
          {isLoading ? (
            <div className="flex flex-col items-center justify-center my-10">
              <Loader />
              <p>Fetching Users...</p>
            </div>
          ) : (
            <UsersList data={data ?? []} />
          )}
        </div>
      </div>
    </main>
  );
}
