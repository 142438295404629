import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import NavBar from "../components/shared/Navbar";
import SidebarRenderer from "../components/shared/SidebarRenderer";
import { getUser } from "../appwrite/atom";
import { ROLES } from "../constants/options";

export default function Layout() {
  const location = useLocation();
  const navigate = useNavigate();

  const { role } = getUser();

  React.useEffect(() => {
    if (location.pathname === "/dashboard" || location.pathname === "/") {
      if (role === ROLES.ADMIN) {
        navigate("/users");
      } else if (role === ROLES.MD) {
        navigate("/md-dashboard");
      } else {
        navigate("/dashboard");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, role]);

  const [visible, setVisible] = React.useState(false);

  return (
    <div className="flex flex-row bg-gray-100 h-screen text-gray-800">
      <aside className="lg:block bg-brand md:shadow w-64 transform transition-transform -translate-x-full md:translate-x-0 duration-150 ease-in hidden sidebar">
        <SidebarRenderer
          showMenu={visible}
          onHide={() => setVisible(!visible)}
        />
      </aside>
      <main className="flex flex-col flex-grow h-screen transition-all duration-150 ease-in overflow-scroll main">
        <NavBar onMenu={() => setVisible(!visible)} />
        <div className="flex flex-col flex-grow p-4 main-content">
          <Outlet />
        </div>
        <footer className="px-4 py-6 footer">
          <div className="footer-content">
            <p className="text-center text-gray-600 text-sm">
              © DLCL IS&T 2024. All rights reserved.
            </p>
          </div>
        </footer>
      </main>
    </div>
  );
}
