import { TableColumn } from "react-data-table-component";
import TableMenu, { MdTableMenu } from "../components/shared/TableMenu";
import type { ReportType, Report } from "../appwrite/types";
import DueDateWaring, { Status } from "../components/dashboard/DueDateWaring";
import { NavigateFunction } from "react-router-dom";

export const dashColumns = (
  navigate: NavigateFunction,
  monthsReports: Report[] | undefined
) =>
  [
    {
      name: "Report Type",
      selector: (row: ReportType) => row?.name,
      grow: 3,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row: ReportType) => row?.name,
      cell: (row: ReportType) => {
        const report = monthsReports?.find(
          (report) => report.name === row.name
        );

        let submitted = false;
        if (report) {
          submitted = report.submitted;
        }

        return (
          <span
            className={`
        py-1 px-3 rounded
        bg-${submitted ? "green" : "yellow"}-100
        text-${submitted ? "green" : "red"}-600
        `}
          >
            {submitted ? "Submitted" : "Pending"}
          </span>
        );
      },
      grow: 1,
      sortable: true,
    },
    {
      name: "Days Left",
      selector: (row: ReportType) => row?.dueDay,
      cell: (row: ReportType) => {
        const report = monthsReports?.find(
          (report) => report.name === row.name
        );

        let submitted = false;
        if (report) {
          submitted = report.submitted;
        }

        return (
          <DueDateWaring dueDay={Number(row.dueDay)} submitted={submitted} />
        );
      },
      grow: 1,
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row: ReportType) => row.id,
      center: true,
      cell: (row: ReportType) => (
        <TableMenu
          onView={() => {
            navigate(`/reports`, {
              state: {
                reportType: row.name,
              },
            });
          }}
          onAdd={() => {
            navigate(`/add-report`, {
              state: {
                reportType: row.name,
              },
            });
          }}
        />
      ),
    },
  ] as TableColumn<ReportType>[];

export const mdDashColumns = (
  navigate: NavigateFunction,
  monthsReports: Report[] | undefined,
  filter: string,
  userReportTypes: ReportType[]
) =>
  [
    {
      name: "Report Type",
      selector: (row: ReportType) => row?.name,
      grow: 3,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row: ReportType) => row?.name,
      cell: (row: ReportType) => {
        const report = monthsReports?.find(
          (report) => report.name === row.name
        );

        let submitted = false;
        if (report) {
          submitted = report.submitted;
        }

        return <Status dueDay={Number(row.dueDay)} submitted={submitted} />;
      },
      grow: 1,
      sortable: true,
    },
    {
      name: "Days Left",
      selector: (row: ReportType) => row?.dueDay,
      cell: (row: ReportType) => {
        const report = monthsReports?.find(
          (report) => report.name === row.name
        );

        let submitted = false;
        if (report) {
          submitted = report.submitted;
        }

        return (
          <DueDateWaring dueDay={Number(row.dueDay)} submitted={submitted} />
        );
      },
      grow: 1,
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row: ReportType) => row.id,
      center: true,
      cell: (row: ReportType) => (
        <MdTableMenu
          onView={() => {
            navigate(`/md-reports`, {
              state: {
                reportType: row.name,
                user: filter,
                reportTypes: userReportTypes,
              },
            });
          }}
        />
      ),
    },
  ] as TableColumn<ReportType>[];
